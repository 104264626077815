var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "d-flex flex-column mb-4",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Exercise categories"))),
          ]),
          _c("v-select", {
            attrs: {
              label: _vm.$t("Choose"),
              outlined: "",
              required: "",
              "single-line": "",
              "small-chips": "",
              loading: _vm.categoryLoading,
              "no-data-text": _vm.$t("No data"),
              items: _vm.categoryList,
              "item-text": "name",
              "item-value": "id",
              rules: [(v) => !!v],
            },
            model: {
              value: _vm.exercise.categoryId,
              callback: function ($$v) {
                _vm.$set(_vm.exercise, "categoryId", $$v)
              },
              expression: "exercise.categoryId",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Exercise type"))),
          ]),
          _c("v-select", {
            attrs: {
              label: _vm.$t("Choose"),
              outlined: "",
              required: "",
              "single-line": "",
              "small-chips": "",
              loading: _vm.typeLoading,
              "return-object": "",
              "no-data-text": _vm.$t("No data"),
              items: _vm.typeList,
              "item-text": "value",
              rules: [(v) => !!v],
            },
            model: {
              value: _vm.type,
              callback: function ($$v) {
                _vm.type = $$v
              },
              expression: "type",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Exercise title"))),
          ]),
          _c("v-text-field", {
            attrs: {
              outlined: "",
              "single-line": "",
              type: "text",
              rules: [(v) => !!v],
              required: "",
              counter: "128",
            },
            model: {
              value: _vm.exercise.title,
              callback: function ($$v) {
                _vm.$set(_vm.exercise, "title", $$v)
              },
              expression: "exercise.title",
            },
          }),
          _c("span", { staticClass: "my-1 d-block" }, [
            _vm._v(_vm._s(_vm.$t("Exercise description"))),
          ]),
          _c("v-textarea", {
            attrs: {
              outlined: "",
              type: "text",
              counter: "600",
              rules: _vm.descriptionRules,
              required: "",
            },
            model: {
              value: _vm.exercise.description,
              callback: function ($$v) {
                _vm.$set(_vm.exercise, "description", $$v)
              },
              expression: "exercise.description",
            },
          }),
          _c(
            "v-expansion-panels",
            { attrs: { flat: "", accordion: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", { staticClass: "px-0" }, [
                    _vm._v(_vm._s(_vm.$t("Advanced"))),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("span", { staticClass: "mt-1 d-block f15" }, [
                        _vm._v(_vm._s(_vm.$t("Follow up"))),
                      ]),
                      _c(
                        "span",
                        { staticClass: "mt-4 primary--text f14 d-block" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "If your client does not do his exercise, follow up"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "w-100 d-block mt-4" },
                        [
                          _c(
                            "span",
                            { staticClass: "d-inline-block ms-0 me-2" },
                            [_vm._v(_vm._s(_vm.$t("Follow up once every")))]
                          ),
                          _c("v-otp-input", {
                            staticClass: "d-inline-block w-20",
                            attrs: {
                              length: "1",
                              type: "number",
                              "hide-spin-buttons": "",
                            },
                            model: {
                              value: _vm.exercise.followUpCount,
                              callback: function ($$v) {
                                _vm.$set(_vm.exercise, "followUpCount", $$v)
                              },
                              expression: "exercise.followUpCount",
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "d-inline-block ms-2 me-0" },
                            [_vm._v(_vm._s(_vm.$t("days")))]
                          ),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "mt-2 mb-1 d-block" }, [
                        _vm._v(_vm._s(_vm.$t("Follow up text"))),
                      ]),
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          type: "text",
                          counter: "255",
                          rules: _vm.followUpTextRules,
                        },
                        model: {
                          value: _vm.exercise.followUpText,
                          callback: function ($$v) {
                            _vm.$set(_vm.exercise, "followUpText", $$v)
                          },
                          expression: "exercise.followUpText",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-1 text-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "col-12",
                  attrs: {
                    color: "primary",
                    large: "",
                    loading: _vm.submit_loading,
                    disabled: _vm.submit_loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }